import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { routes } from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async function(to, from, next) {
  console.log("Checking auth")

  const authToken = localStorage.getItem('gbtkd-ukpa-auth');
  const isAuthed = !!authToken; // convert to boolean

  // Check we are logged in, everything requires authentication.
  if (!isAuthed && to.meta.auth) {
    localStorage.setItem('last_url', to.path);
    router.push('/login');
  }


  
  return next();
});

export default router
// 