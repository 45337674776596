<template>
  <div id="app" class="d-flex flex-column " style="overflow-x: hidden">
    <navbar />
    
    <div class="row flex-grow-1 h-100" style="overflow-x: hidden">
      <div class=" h-100 border-end m-custom-sidebar" :class="{ 
        'col-xs-12 col-md-3 col-lg-2': !isSidebarHidden,
        'd-none': isSidebarHidden

        }">
        <Sidebar />
      </div>

      <div class="h-100" style="overflow-y: auto;" :class="{ 
        'col-xs-12 col-md-9 col-lg-10': !isSidebarHidden,
        'col-12': isSidebarHidden 
        }">
        <router-view :key="$route.fullPath" />
      </div>
    </div>

    <!-- <my-footer /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import Sidebar from "./components/Sidebar.vue";

import { getAthletes } from "./api";


export default {
  components: { Navbar, 'my-footer': Footer, Sidebar  },
  
  async created() {
    
  },
  async mounted() {
    // Handle SPA Auth
    try {
      const authKey = 'gbtkd-ukpa-auth';
      const authToken = localStorage.getItem(authKey);

      if (authToken) {
        this.$store.dispatch('auth', authToken);
      }

    } catch (e) {
        this.Swal.fire({ title: "Unable to authorize!", text: e.message, icon: "error" });
    }

    // User should be logged in now...

    console.log(`Base url:` + process.env.BASE_URL);

    await this.$store.dispatch('getUser', 'me');
    this.$store.dispatch('getAthletes');
    
  },
  computed: {
    ...mapGetters([ 'siteConfig' ]),
    isSidebarHidden() { return this.siteConfig.isSidebarHidden || false; }

  }
}
</script>

<style scoped>
/* Add a margin so it works when on a mobile  */
.m-custom-sidebar {
  margin-top: 0px;
}

@media (max-width: 576px) { 
  .m-custom-sidebar {
    /* margin-top: 600px; */
  }
}
</style>