<template>
    <div class="ms-3 me-1 mt-3" style="height: calc(100% - 56px)">
        <div class=" d-flex justify-content-between">
            <div class="h3">Tracked Athletes</div>
        </div>

        <div class="overflow-auto h-100">

            <div class="w-100">
                <!-- <button class="text-right btn btn-success btn-block" data-bs-toggle="modal" data-bs-target="#exampleModal">Add Athlete</button> -->
            </div>

            <div v-for="athlete in trackedAthletes" class="my-2" v-bind:key="athlete.id" >
                <router-link :to="{ name: 'Athlete', params: { id: athlete.id } }" v-slot="{ navigate }" custom>
                    <div class="athlete" @click="navigate">
                        <div class="me-1 my-auto">
                            <img v-if="athlete.avatar" class="img img-avatar" :src="athlete.avatar" alt="">
                            <img v-else class="img img-avatar" src="@/assets/avatar.png" alt="">
                        </div>
                        <div style="position: relative" class="ms-4 my-auto">
                            <div class="athlete-name">{{athlete.lastName}}, {{ athlete.firstName }}</div>
                            <div><country-flag :country='athlete.country' :rounded='true' size="small" :shadow="true" style="position: absolute; top: -9px; left: -20px;"/></div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>

        <div class="modal fade " tabindex="-1" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true"  data-backdrop="false">
            <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Add an Athlete</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="form-group col">
                                <label for="inputEmail4">First Name</label>
                                <input type="firstName" class="form-control" id="inputFirstName" placeholder="John">
                            </div>
                            <div class="form-group col">
                                <label for="lastName">Last Name</label>
                                <input type="text" class="form-control" id="lastName" placeholder="Doe">
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label for="inputEmail4">Country</label>
                                <input type="firstName" class="form-control" id="inputFirstName" placeholder="John">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['trackedAthletes', 'athletes']),
    },
    methods: {
        ...mapActions(['selectAccount', 'selectAthlete']),
    }
}
</script>

<style lang="scss" scoped>

.athlete {
    display: flex;
    // line-height: 39px;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    background-color: #e3e3e3e3;
    /* padding-left: 10px */
    padding: 5px 5px;
    cursor: pointer;
    // height: 39px;
    font-size: 0.875em;
    vertical-align: middle;
}

.athlete-name {
    // line-height: 30px;
    font-weight: bold;
    
}

.img-circle {
    border-radius: 50% !important;
}

.img-avatar {
  height: 40px;
  width: 40px;
  object-fit: contain;
//   border: 1px solid #d3d3d3;
}
</style>