var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"footer align-content-bottom bg-light d-none d-md-flex"},[_c('div',{staticClass:"container-md d-flex flex-column flex-md-row flex-wrap justify-content-between align-items-center py-3"},[_vm._m(0),_c('ul',{staticClass:"nav justify-content-end list-unstyled d-flex"},[_c('router-link',{attrs:{"to":"/terms","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"ms-3"},[_c('a',{staticClass:"text-muted",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Terms & Conditions")])])]}}])}),_c('router-link',{attrs:{"to":"/privacy","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"ms-3"},[_c('a',{staticClass:"text-muted",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Privacy Policy")])])]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"d-block my-auto"},[_vm._v("© 2021 SHU-PAT")]),_c('span',{staticClass:"text-muted ms-1 d-block",staticStyle:{"font-size":"0.8rem"}},[_vm._v("This app is not officially endorsed by Sheffield Hallam University. It has been created by Shaun Barratt (Ph.D candidate) in persuit of study")])])}]

export { render, staticRenderFns }