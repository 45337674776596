import axios from "axios";

const instance = axios.create({ 
    baseURL: process.env.VUE_APP_SERVER_BASE_URL,
    headers: {
        // 'Access-Control-Allow-Origin': '*',
    },
});

// Users
export function postAuthLogin({ username, password }) {
    return instance.post(`/Auth/login`, { username, password });
}

export function postAuthRegister(registerPayload) {
    return instance.post(`/Auth/register`, registerPayload);
}
export function getUser(userId) {
    return instance.get(`/Users/${userId}`);
}

// Athletes
export function getAthlete(athleteId) {
    return instance.get(`/Athletes/${athleteId}`);
}

export function getAthletes(params) {
    return instance.get(`/Athletes`, { params });
}

export function upsertAthlete(data, params) {
    return instance.post(`/Athletes/${data.id}`, data, { params });
}

export function patchAthlete(athleteId, data) {
    return instance.patch(`/Athletes/${athleteId}`, data);
}

export function deleteAthlete(athleteId) {
    return instance.delete(`/Athletes/${athleteId}`);
}

// Matches
export function createMatch(data) {
    return instance.post(`/Matches`, data);
}

export function getMatches(params) {
    return instance.get(`/Matches`, { params: params });
}
export function getMatch(matchId) {
    return instance.get(`/Matches/${matchId}`);
}
export function deleteMatch(matchId) {
    return instance.delete(`/Matches/${matchId}`);
}


// Viewpoints
export function createViewpoint(data) {
    return instance.post(`/Viewpoints`, data);
}

export function getViewpoints(params) {
    return instance.get(`/Viewpoints`, { params: params });
}

export function upsertViewpoint(data) { // alias
    return createViewpoint(data);
}

export function deleteViewpoint(viewpointId) {
    return instance.delete(`/Viewpoints/${viewpointId}`);
}

// Tasks
export function createTask(data) {
    return instance.post(`/Tasks`, data);
}
export function deleteTask(taskId) {
    return instance.delete(`/Tasks/${taskId}`);
}
export function getTasks(params) {
    return instance.get(`/Tasks`, { params: params });
}
export function upsertTask(data, params) {
    return instance.post('/Tasks', data, { params });
}

// Frames
export function getFrames(params) {
    return instance.get('/Frames', { params });
}
export function upsertFrame(data, params) {
    return instance.post('/Frames', data, { params });
}

export default instance;