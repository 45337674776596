import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios, {
  getUser, 

  getAthletes, 
  getAthlete, 

  getMatch, 
  deleteMatch,
  
  getViewpoints, 
  getViewpoint,
  upsertViewpoint,
  deleteViewpoint,
  
  getTasks, 
  getTask,
  deleteTask,
} from '../api';

const vuexLocal = new VuexPersistence({
  key: 'vuex-shupa',
  storage: window.localStorage,
  reducer: (state) => ({
    auth: state.auth
  })
});

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    user: null,
    accessToken: null,

    athletes: {},
    selectedAthleteId: null,

    matches: {},
    selectedMatchId: null,

    tasks: {},
    selectedTaskId: null,

    viewpoints: {},
    selectedViewpointId: null,

    siteConfig: {}
  },
  getters: {
    loggedIn: state => state.accessToken,
    siteConfig: state => state.siteConfig,
    user: state => state.user,
    athletes: state => Object.values(state.athletes),
    trackedAthletes: state => Object.values(state.athletes).filter(x => x.tracked),

    selectedAthlete: state => state.athletes[state.selectedAthleteId],
    selectedAthleteMatches: (state, getters) => getters.matches.filter(x => x.athletes[getters.selectedAthlete.id]),
    selectedAthleteViewpoints: (state, getters) => getters.viewpoints.filter(x => x.athletes[getters.selectedAthlete.id]),

    matches: state => Object.values(state.matches),
    selectedMatch: state => state.matches[state.selectedMatchId],

    tasks: state => Object.values(state.tasks),
    selectedTask: state => state.tasks[state.selectedTaskId],
    selectedTasksByAthlete: (state) => state.tasks.filter(x => x.athletes[state.selectedAthleteId]),

    viewpoints: state => Object.values(state.viewpoints),
    selectedViewpoint: state => state.viewpoints[state.selectedViewpointId],
  },
  mutations: {
    UPDATE_SITE_CONFIG: (state, { key, value }) => {
      Vue.set(state.siteConfig, key, value);
    },
    UPDATE_ATHLETE: (state, athlete) => {
      Vue.set(state.athletes, athlete.id, athlete);
    },
    SELECT_ATHLETE: (state, athleteId) => {
      state.selectedAthleteId = athleteId
    },
    DELETE_ATHLETE: (state, athleteId) => {
      Vue.set(state.athletes, athleteId, undefined);
    },
    
    UPDATE_MATCH: (state, match) => {
      Vue.set(state.matches, match.id, match);
    },
    SELECT_MATCH: (state, matchId) => {
      state.selectedMatchId = matchId
    },
    DELETE_MATCH: (state, matchId) => {
      Vue.set(state.matches, matchId, undefined);
    },

    UPDATE_VIEWPOINT: (state, viewpoint) => {
      Vue.set(state.viewpoints, viewpoint.id, viewpoint);
    },
    SELECT_VIEWPOINT: (state, viewpointId) => {
      state.selectedViewpointId = viewpointId
    },
    DELETE_VIEWPOINT: (state, viewpointId) => {
      Vue.set(state.viewpoints, viewpointId, undefined);
    },

    UPDATE_TASK: (state, task) => {
      Vue.set(state.tasks, task.id, task);
    },
    SELECT_TASK: (state, taskId) => {
      state.selectedTaskId = taskId
    },
    DELETE_TASK: (state, taskId) => {
      Vue.set(state.tasks, taskId, undefined);
    },
  },
  actions: {
    async getAthlete(context, athleteId) {
      let athleteResponse = await getAthlete(athleteId);
      context.commit("UPDATE_ATHLETE", athleteResponse.data);
    },
    async getAthletes(context) {
      let athleteResponse = await getAthletes();
      for (let athlete of athleteResponse.data.athletes)
        context.commit("UPDATE_ATHLETE", athlete);  
    },
    selectAthlete(context, athleteId) {
      context.commit("SELECT_ATHLETE", athleteId);
    },


    async getMatch(context, matchId) {
      let matchResponse = await getMatch(matchId);
      context.commit("UPDATE_MATCH", matchResponse.data);
    },
    selectMatch(context, matchId) {
      context.commit("SELECT_MATCH", matchId);
    },

    async deleteMatch(context, matchId) {
      // Save our current idea of the object.
      let newMatchResponse = await deleteMatch(matchId);
      context.commit("DELETE_MATCH", matchId);
    },

    async getViewpoint(context, viewpointId) {
      let viewpointsResponse = await getViewpoints({ id: viewpointId });
      for (let viewpoint of viewpointsResponse.data.viewpoints)
        context.commit("UPDATE_VIEWPOINT", viewpoint);
    },
    async getViewpoints(context) {
      let viewpointsResponse = await getViewpoints();
      for (let viewpoint of viewpointsResponse.data.viewpoints)
        context.commit("UPDATE_VIEWPOINT", viewpoint);  
    },
    async upsertViewpoint(context, viewpointId) {
      // Save our current idea of the object.
      let newViewpointResponse = await upsertViewpoint(context.state.viewpoints[viewpointId]);
      context.commit("UPDATE_VIEWPOINT", newViewpointResponse.data);
    },
    async deleteViewpoint(context, viewpointId) {
      // Save our current idea of the object.
      let newViewpointResponse = await deleteViewpoint(viewpointId);
      context.commit("DELETE_VIEWPOINT", viewpointId);
    },
    selectViewpoint(context, viewpointId) {
      context.commit("SELECT_VIEWPOINT", viewpointId);
    },

    async getTask(context, taskId) {
      const { resources: tasks } = (await getTasks({ id: taskId })).data;
      for (let task of tasks)
        context.commit("UPDATE_TASK", task);
    },
    async getTasks(context) {
      const { resources: tasks } = (await getTasks()).data;
      for (let task of tasks)
        context.commit("UPDATE_TASK", task);  
    },
    async deleteTask(context, taskId) {
      // Save our current idea of the object.
      let newTaskResponse = await deleteTask(taskId);
      context.commit("DELETE_TASK", taskId);
    },
    selectTask(context, taskId) {
      context.commit("SELECT_TASK", taskId);
    },
    auth(context, accessToken) {
      console.log("setting auth")
      context.state.accessToken = accessToken;
      localStorage.setItem('gbtkd-ukpa-auth', accessToken)
      console.log("Got access token.")
      axios.defaults.headers["authorization"] = accessToken;
    },
    // for now this is only used for logging in, eventually it will cache other users for perimssions too.
    async getUser(context, userId) {
      const user = (await getUser(userId)).data;
      context.state.user = user;
    },
    user(context, user) {
      context.state.user = user;
    },
    logout(context) {
      context.state.user = null;
      context.state.auth = null;
      localStorage.clear();
      window.location = '/'

    }
  },
  modules: {

  },
  plugins: [ vuexLocal.plugin ]
});

export default store

if (store.state.auth) axios.defaults.headers["authorization"] = store.state.auth.token;