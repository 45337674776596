var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('nav',{staticClass:"navbar navbar-expand-sm navbar-light bg-light",staticStyle:{"border-bottom":"1px solid #c3c3c3"}},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"d-none d-sm-block navbar-brand py-4 ms-xs-0 ms-lg-5",attrs:{"href":href},on:{"click":navigate}},[_vm._v("SHU - Player Analsis Tool")]),_c('a',{staticClass:"d-block d-sm-none navbar-brand py-4 ms-xs-0 ms-lg-5",attrs:{"href":href},on:{"click":navigate}},[_vm._v("SHU - PAT")])]}}])}),_c('div',{staticClass:"collapse navbar-collapse navbar-expand-sm",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav me-auto"},[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{ 'active': isExactActive },attrs:{"aria-current":"page","href":href},on:{"click":navigate}},[_vm._v("Home")])])]}}])}),_c('router-link',{attrs:{"to":"/viewpoints","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{ 'active': isExactActive },attrs:{"aria-current":"page","href":href},on:{"click":navigate}},[_vm._v("Viewpoints")])])]}}])}),_c('router-link',{attrs:{"to":"/annotate","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{ 'active': isExactActive },attrs:{"aria-current":"page","href":href},on:{"click":navigate}},[_vm._v("Annotation")])])]}}])}),_c('router-link',{attrs:{"to":"/task-processors","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{ 'active': isExactActive },attrs:{"aria-current":"page","href":href},on:{"click":navigate}},[_vm._v("Task Processors")])])]}}])}),_c('router-link',{attrs:{"to":"/athletes","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{ 'active': isExactActive },attrs:{"aria-current":"page","href":href},on:{"click":navigate}},[_vm._v("Athletes")])])]}}])})],1)]),_c('div',{staticClass:"ms-1 me-lg-5"},[_c('div',{},[(_vm.user)?_c('div',[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-item title"},[_vm._v(" "+_vm._s(_vm.user.username)+" "),_c('a',{attrs:{"href":"#"},on:{"click":_vm.logout}},[_vm._v("(Sign out)")])])])]):(_vm.loggedIn)?_c('div',[_vm._m(1)]):_c('div',[_c('button',{staticClass:"btn btn-success",on:{"click":function($event){return _vm.$router.push('/Login')}}},[_vm._v("Sign in")]),_c('button',{staticClass:"btn btn-outline-success ms-3",on:{"click":function($event){return _vm.$router.push('/Register')}}},[_vm._v("Register")])])])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#navbarSupportedContent","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"level"},[_c('div',{staticClass:"level-item title"},[_vm._v(" Logging in... Please wait ")])])}]

export { render, staticRenderFns }