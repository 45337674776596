<template>
  <header>
    <nav class="navbar navbar-expand-sm navbar-light bg-light" style="border-bottom: 1px solid #c3c3c3;">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <router-link
          to="/"
          v-slot="{ href, navigate }"
          custom
        >
          <a class="d-none d-sm-block navbar-brand py-4 ms-xs-0 ms-lg-5" :href="href" @click="navigate">SHU - Player Analsis Tool</a>
          <a class="d-block d-sm-none navbar-brand py-4 ms-xs-0 ms-lg-5" :href="href" @click="navigate">SHU - PAT</a>
        </router-link>
        <div class="collapse navbar-collapse navbar-expand-sm" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto">
            <router-link
              to="/"
              v-slot="{ href, navigate, isExactActive }"
              custom
            >
              <li class="nav-item">
                <a class="nav-link" :class="{ 'active': isExactActive }" aria-current="page" :href="href" @click="navigate">Home</a>
              </li>
            </router-link>
            <router-link to="/viewpoints" v-slot="{ href, navigate, isExactActive }" custom>
              <li class="nav-item">
                <a class="nav-link" :class="{ 'active': isExactActive }" aria-current="page" :href="href" @click="navigate">Viewpoints</a>
              </li>
            </router-link>
            <router-link to="/annotate" v-slot="{ href, navigate, isExactActive }" custom>
              <li class="nav-item">
                <a class="nav-link" :class="{ 'active': isExactActive }" aria-current="page" :href="href" @click="navigate">Annotation</a>
              </li>
            </router-link>
            <router-link to="/task-processors" v-slot="{ href, navigate, isExactActive }" custom>
              <li class="nav-item">
                <a class="nav-link" :class="{ 'active': isExactActive }" aria-current="page" :href="href" @click="navigate">Task Processors</a>
              </li>
            </router-link>
            <router-link to="/athletes" v-slot="{ href, navigate, isExactActive }" custom>
              <li class="nav-item">
                <a class="nav-link" :class="{ 'active': isExactActive }" aria-current="page" :href="href" @click="navigate">Athletes</a>
              </li>
            </router-link>
          </ul>
        </div>

          <!-- <ul class="navbar-nav ms-auto mb-2 mb-lg-0"> -->
          <!-- </ul> -->
        <div class="ms-1 me-lg-5">
          <div class="">
            <div v-if="user">
              <div class="level">
                <div class="level-item title">
                  {{ user.username }} 
                  <a href="#" @click="logout">(Sign out)</a>
                </div>
              </div>
            </div>
            <div v-else-if="loggedIn">
              <div class="level">
                <div class="level-item title">
                  Logging in... Please wait
                </div>
              </div>
            </div>
            <div v-else>
              <button class="btn btn-success" @click="$router.push('/Login')">Sign in</button>
              <button class="btn btn-outline-success ms-3" @click="$router.push('/Register')">Register</button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
  },
  methods: {
    ...mapActions([ 'logout' ])
  },
  computed: {
    ...mapGetters([ 'user', 'loggedIn' ])
  }
};
</script>

<style>

</style>