import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Swal from "sweetalert2"
let CustomSwal = Swal.mixin({ heightAuto: false, });

import CountryFlag from 'vue-country-flag'
Vue.component('country-flag', CountryFlag)

import Multiselect from 'vue-multiselect'
// register globally
Vue.component('multiselect', Multiselect)
import 'vue-multiselect/dist/vue-multiselect.min.css'

import '@/scss/custom.scss'
import '@popperjs/core'
import 'bootstrap/dist/js/bootstrap'
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

import "./apimock"; // Mock our API requests

import JsonCSV from 'vue-json-to-csv'
Vue.component('downloadCsv', JsonCSV)

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(require('vue-moment'));

Vue.mixin({
  methods: {
    todo: () => CustomSwal.fire("Todo", "This function is a work in progress, go nag Shaun for an update"),
    Swal: CustomSwal
  }
})
import InfiniteLoading from 'vue-infinite-loading';

Vue.use(InfiniteLoading, { /* options */ });

let instance = new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')