var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column ",staticStyle:{"overflow-x":"hidden"},attrs:{"id":"app"}},[_c('navbar'),_c('div',{staticClass:"row flex-grow-1 h-100",staticStyle:{"overflow-x":"hidden"}},[_c('div',{staticClass:" h-100 border-end m-custom-sidebar",class:{ 
      'col-xs-12 col-md-3 col-lg-2': !_vm.isSidebarHidden,
      'd-none': _vm.isSidebarHidden

      }},[_c('Sidebar')],1),_c('div',{staticClass:"h-100",class:{ 
      'col-xs-12 col-md-9 col-lg-10': !_vm.isSidebarHidden,
      'col-12': _vm.isSidebarHidden 
      },staticStyle:{"overflow-y":"auto"}},[_c('router-view',{key:_vm.$route.fullPath})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }