export const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    },
    {
        path: "/Login",
        name: "Login",
        component: () => import(/* webpackChunkName: "home" */ '../views/Login.vue'),
    },
    {
        path: "/register",
        name: "Register",
        component: () => import(/* webpackChunkName: "home" */ '../views/Register.vue'),
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: "/athletes",
        name: "Athletes",
        component: () => import(/* webpackChunkName: "athletes" */ '../views/Athletes.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: "/athletes/:id",
        name: "Athlete",
        component: () => import(/* webpackChunkName: "athlete" */ '../views/Athlete.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: "/matches/:id",
        name: "Match",
        component: () => import(/* webpackChunkName: "match" */ '../views/Match.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: "/viewpoints",
        name: "Viewpoints",
        component: () => import(/* webpackChunkName: "viewpoints" */ '../views/Viewpoints.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: "/task-processors",
        name: "TaskProcessors",
        component: () => import(/* webpackChunkName: "taskProcessors" */ '../views/TaskProcessors.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: "/viewpoint/:id",
        name: "Viewpoint",
        component: () => import(/* webpackChunkName: "viewpoint" */ '../views/Viewpoint.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: "/viewpoint/:id/annotate",
        name: "Annotate",
        component: () => import(/* webpackChunkName: "viewpoint-annotate" */ '../views/Viewpoint/Annotate.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: "/viewpoint/:id/annotate-kicks",
        name: "AnnotateKick",
        component: () => import(/* webpackChunkName: "viewpoint-annotate-kicks" */ '../views/Viewpoint/AnnotateKicks.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: "/annotate",
        name: "AnnotateGeneral",
        component: () => import(/* webpackChunkName: "viewpoint-annotate-general" */ '../views/VideoAnnotationClassification.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: "/task/:id",
        name: "Task",
        component: () => import(/* webpackChunkName: "task" */ '../views/Task.vue'),
        meta: {
            auth: true,
        }
    },
];