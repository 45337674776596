
import axios from "./api";
import MockAdapter from "axios-mock-adapter";

var mock = new MockAdapter(axios, { delayResponse: 200, onNoMatch: "passthrough" });

let baseUrl = process.env.VUE_APP_SERVER_BASE_URL;

let mockAthletes = [
    {
      "lastName": "CHO",
      "firstName": "Mahama",
      "names": [
        "CHO",
        "Mahama"
      ],
      "country": "GBR",
      "discipline": "TKD",
      "avatar": "https://olympics.com/tokyo-2020/olympic-games/resOG2020-/img/bios/photos/thumb/1440120.jpg",
      "olympicCode": "1440120",
      "id": "CHO_Mahama_GBR"
    },
    {
      "lastName": "JONES",
      "firstName": "Jade",
      "names": [
        "JONES",
        "Jade"
      ],
      "country": "GBR",
      "discipline": "TKD",
      "avatar": "https://olympics.com/tokyo-2020/olympic-games/resOG2020-/img/bios/photos/thumb/1440121.jpg",
      "olympicCode": "1440121",
      "id": "JONES_Jade_GBR"
    },
    {
      "lastName": "SINDEN",
      "firstName": "Bradly",
      "names": [
        "SINDEN",
        "Bradly"
      ],
      "country": "GBR",
      "discipline": "TKD",
      "avatar": "https://olympics.com/tokyo-2020/olympic-games/resOG2020-/img/bios/photos/thumb/1440125.jpg",
      "olympicCode": "1440125",
      "id": "SINDEN_Bradly_GBR"
    },
    {
      "lastName": "WALKDEN",
      "firstName": "Bianca",
      "names": [
        "WALKDEN",
        "Bianca"
      ],
      "country": "GBR",
      "discipline": "TKD",
      "avatar": "https://olympics.com/tokyo-2020/olympic-games/resOG2020-/img/bios/photos/thumb/1440119.jpg",
      "olympicCode": "1440119",
      "id": "WALKDEN_Bianca_GBR"
    },
    {
      "lastName": "WILLIAMS",
      "firstName": "Lauren",
      "names": [
        "WILLIAMS",
        "Lauren"
      ],
      "country": "GBR",
      "discipline": "TKD",
      "avatar": "https://olympics.com/tokyo-2020/olympic-games/resOG2020-/img/bios/photos/thumb/1440126.jpg",
      "olympicCode": "1440126",
      "id": "WILLIAMS_Lauren_GBR"
    }
];

// mock.onGet(`/Athletes`).reply(200, {
//     athletes: mockAthletes
// });

// mock.onGet(new RegExp(`\/Athletes\/(\\w+)$`)).reply((config) => {
//     let athleteId = (new RegExp(`\/Athletes\/(\\w+)$`)).exec(config.url)[1];
//     console.log(`getting athlete ${athleteId}`)
//     let athlete = mockAthletes.find(x => x.id == athleteId);
//     console.log(`got ${athlete.id}`)
//     if (athlete) return [ 200, athlete ];
//     else return [ 404, null ];
// });