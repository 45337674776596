<template>

    <footer class="footer align-content-bottom bg-light d-none d-md-flex">
        <div class="container-md d-flex flex-column flex-md-row flex-wrap justify-content-between align-items-center py-3">
            <div class="d-flex align-items-center">
                <span class="d-block my-auto" >© 2021 SHU-PAT</span>
                <span class="text-muted ms-1 d-block" style="font-size:0.8rem;">This app is not officially endorsed by Sheffield Hallam University. It has been created by Shaun Barratt (Ph.D candidate) in persuit of study</span>
            </div>

            <ul class="nav justify-content-end list-unstyled d-flex">
                <router-link
                    to="/terms"
                    v-slot="{ href, navigate, isExactActive }"
                    custom
                >
                    <li class="ms-3"><a class="text-muted" :href="href" @click="navigate">Terms &amp; Conditions</a></li>
                </router-link>
                <!-- <router-link
                    to="/gdpr"
                    v-slot="{ href, navigate, isExactActive }"
                    custom
                >
                    <li class="ms-3"><a class="text-muted" :href="href" @click="navigate">GDPR</a></li>
                </router-link> -->
                <router-link
                    to="/privacy"
                    v-slot="{ href, navigate, isExactActive }"
                    custom
                >
                    <li class="ms-3"><a class="text-muted" :href="href" @click="navigate">Privacy Policy</a></li>
                </router-link>
            </ul>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>